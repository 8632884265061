import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "./fun-stuff.css"

const Work = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title || "Title"
  const projects = data.projects.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Fun Stuff" />
      <ol className="list-none dark:text-amber-50 mb-40">
        {projects.map(project => (
          <li key="project.id">
            <article
              className="prose md:prose-xl lg:prose-2xl max-w-prose md:max-w-2xl lg:max-w-4xl dark:prose-headings:text-amber-50 dark:prose-a:text-amber-50 dark:prose-blockquote:text-amber-100 dark:prose-strong:text-amber-50 dark:text-amber-50 m-2 sm:m-auto"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <h2>
                  {project.frontmatter.link ? (
                    <Link to={project.frontmatter.link} itemProp="url">
                      <span>{project.frontmatter.title}</span>
                    </Link>
                  ) : (
                    <span>{project.frontmatter.title}</span>
                  )}
                </h2>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: project.html,
                  }}
                  itemProp="articleBody"
                ></p>
              </section>
            </article>
          </li>
        ))}
      </ol>
    </Layout>
  )
}

export default Work

export const pageQuery = graphql`
  query WorkQuery {
    site {
      siteMetadata {
        title
      }
    }
    projects: allMarkdownRemark(
      sort: { fields: [frontmatter___order] }
      filter: { fileAbsolutePath: { regex: "/work/" } }
    ) {
      nodes {
        frontmatter {
          title
          link
        }
        html
        id
      }
    }
  }
`
